@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --c-gray-900: #acacac;
  --c-gray-800: #fff1f1;
  --c-gray-700: #2e2e2e;
  --c-gray-600: #d5d5d5;
  --c-gray-500: #969593;
  --c-gray-400: #a6a6a6;
  --c-gray-300: #bdbbb7;
  --c-gray-200: #000000;
  --c-gray-100: #000000;
  --c-green-500: #45ffbc;
  --c-olive-500: #e3ffa8;
  --c-white: var(--c-gray-100);
  --c-text-primary: var(--c-gray-100);
  --c-text-secondary: var(--c-gray-200);
  --c-text-tertiary: var(--c-gray-500);
}
.hide {
  display: none;
}
.show {
  display: block;
}
body {
  line-height: 1.5 !important;
  min-height: 100vh !important;
  font-family: "Be Vietnam Pro", sans-serif !important;
  background-color: var(--c-gray-900) !important;
  color: var(--c-text-primary) !important;
  padding-top: 3vw !important;
  padding-bottom: 3vw !important;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  display: block;
  max-width: 100%;
  margin-left: 10px;
}

button,
select,
input,
textarea {
  font: inherit;
}

a {
  color: #969593 !important;
}
input[type="file"] {
  min-width: 200px;
  max-width: 220px;
}
.supplierName-select {
  font-size: 13px;
}
.textarea-input {
  margin-top: 15px;
  background-color: #fff1f1;
  height: 115px;
  width: 250px;
  padding: 7px;
  border-radius: 7px;
}
.responsive-wrapper {
  width: 90%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
.allrecords-heading {
  margin-left: -37px;
}
.switch-order {
  white-space: nowrap;
}
.invoice-items {
  width: 800px;
  margin-top: 25px;
}
.app {
  min-height: 80vh;
  min-width: 320px;
  width: 100%;
  background-color: var(--c-gray-800);
  padding: 2vw 4vw 6vw;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767.98px) {
  .app {
    min-height: 100vh;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .app {
    min-height: 90vh;
  }
}
.delete-img,
.edit-img {
  display: inline;
}
.delete-img:hover,
.edit-img:hover {
  cursor: pointer;
}
.alert {
  margin-left: -23px;
  width: 95%;
  margin-top: 20px;
  border-radius: 10px;
  padding: 1rem;
}

.deleteconfirm-box {
  background-color: yellow;
  width: 300px;
  height: 300px;
}
.table-responsive {
  overflow-x: auto;
}
@media (max-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 806px;
  }
}
@media (max-width: 767px) {
  .table-responsive table {
    width: 100%;
  }
  .invoice-items {
    width: 450px;
  }
}
.responsive-select {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1400px) {
  .responsive-select {
    flex-direction: row;
  }
}
.app-header {
  display: grid;
  grid-template-columns:
    minmax(-webkit-min-content, 175px) minmax(-webkit-min-content, 500px)
    minmax(-webkit-max-content, 400px);
  grid-template-columns: minmax(min-content, 175px) minmax(min-content, 500px) minmax(
      max-content,
      400px
    );
  -moz-column-gap: 4rem;
  column-gap: 4rem;
  align-items: flex-end;
}
@media (max-width: 800px) {
  .app-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--c-gray-600);
  }
  .alert {
    margin-left: -45px;
    width: 112%;
    margin-top: 20px;
    border-radius: 10px;
    font-size: 14px;
  }
  .table {
    margin-left: -35px !important;
  }
  .product-list {
    width: 400px;
  }
  .accordion-body .table-box {
    padding-left: 60px;
  }
}
@media (max-width: 800px) {
  .app-header-navigation {
    display: none;
  }
  .table {
    font-size: 10px;
  }
  .addrecord-form {
    margin-left: -50px;
  }
  .menu-items {
    width: 57vh !important;
  }
}
@media (max-width: 450px) {
  .addrecord-form {
    font-size: 15px;
  }
  .order-input,
  .tracking-input,
  .post-input,
  .date-input,
  .status-input,
  .location-input,
  .trackingid-input {
    width: 50%;
  }
  .search-header-navigation {
    margin-left: -27px;
    font-size: 14px;
  }
  .search-tracking {
    margin-left: -27px;
  }
  .table {
    font-size: 8px;
  }
  .service-section {
    margin-left: 45px;
  }
  .menu-items {
    width: 84vw !important;
  }

  .app-header {
    margin-left: 27px !important;
  }
  .table-box {
    width: 322px !important;
    overflow: auto;
    padding-left: 50px !important;
    margin-left: -50px !important;
  }
  .product-list {
    width: 365px !important;
  }
  .generate-estimate-btn,
  .generate-invoice-btn {
    font-size: 13px;
  }
  .invoiceDetail-input {
    width: 180px;
  }
  .invoice-items {
    width: 344px !important;
  }
  .invoice-details {
    width: 365px !important;
  }
  .accordion-invoice-details {
    display: block !important;
  }
  .accordion-invoice-number {
    margin-bottom: 5px;
    width: 160px !important;
  }
  .mobile-tabs {
    margin-left: 35px;
  }
  .edit-invoice-dialoge-pc {
    width: auto !important;
  }
  .product-box {
    margin-left: 0px !important;
    padding-left: 28px !important    ;
    /* max-width: 100% !important; */
  }
  .react-autosuggest__suggestions-container {
    background-color: #fff !important;
  }
  .react-autosuggest__suggestions-container--open {
    height: 350px !important;
  }
  .edit-scroll-conatiner {
    width: 370px !important;
  }
  .app-body-main-content {
    max-width: 80vw !important;
  }
  .btn-addanyways {
    margin-top: 5px !important;
    margin-left: 0px !important;
  }
  .edit-order-report-dialoge {
    width: 100vw !important;
  }
}
.table-box {
  height: 700px;
  overflow: auto;
  padding-left: 103px;
  margin-left: -97px;
}
.table-box-previous-order {
  height: 200px !important;
  overflow: none !important;
}
@media (max-width: 1000px) {
  .logo-title {
    font-size: 14px !important;
  }
  .table {
    font-size: 13px;
  }
  .switch-order {
    font-size: 13px;
  }
  .product-list {
    width: 450px;
  }
  .invoice-details {
    width: 600px;
  }
}

.app-header-actions {
  display: flex;
  align-items: center;
}
@media (max-width: 800px) {
  .app-header-actions {
    display: none;
  }
}
@media (max-width: 1200px) {
  .invoice-items {
    width: 600px;
  }
}

.app-header-actions-buttons {
  display: flex;
  border-left: 1px solid var(--c-gray-600);
  margin-left: 2rem;
  padding-left: 2rem;
}
.app-header-actions-buttons > * + * {
  margin-left: 1rem;
}

.app-header-mobile {
  display: none;
}
@media (max-width: 800px) {
  .app-header-mobile {
    display: flex;
  }
}
.edit-invoice-dialoge-pc {
  width: -webkit-fill-available;
}
.app-body {
  height: 100%;
  display: grid;
  grid-template-columns: minmax(-webkit-min-content, 175px) minmax(
      -webkit-max-content,
      1fr
    );
  grid-template-columns: minmax(min-content, 175px) minmax(max-content, 1fr);
  -moz-column-gap: 4rem;
  column-gap: 4rem;
  padding-top: 2.5rem;
}
@media (max-width: 800px) {
  .app-body {
    grid-template-columns: 1fr;
  }
  .app-body > * {
    margin-bottom: 3.5rem;
  }
}

.app-body-navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .app-body-navigation {
    display: none;
  }
}

.footer {
  margin-top: auto;
}
.footer h1 {
  font-size: 1.5rem;
  line-height: 1.125;
  display: flex;
  align-items: flex-start;
}
.footer h1 small {
  font-size: 0.5em;
  margin-left: 0.25em;
}
.footer div {
  border-top: 1px solid var(--c-gray-600);
  margin-top: 1.5rem;
  padding-top: 1rem;
  font-size: 0.75rem;
  color: var(--c-text-tertiary);
}

.logo {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid var(--c-gray-600);
}
@media (max-width: 800px) {
  .logo {
    border-bottom: 0;
  }
}

.logo-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.logo-title {
  display: flex;
  flex-direction: column;
  line-height: 1.25;
  margin-left: 0.75rem;
}
.logo-title span:first-child {
  color: var(--c-text-primary);
}
.logo-title span:last-child {
  color: var(--c-text-tertiary);
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--c-text-tertiary);
}
.navigation a {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 0.25s ease;
}
.navigation a * {
  transition: 0.25s ease;
}
.navigation a i {
  margin-right: 0.75rem;
  font-size: 1.25em;
  flex-shrink: 0;
}
.navigation a + a {
  margin-top: 1.25rem;
}
.navigation a:hover,
.navigation a:focus {
  transform: translateX(4px);
  color: var(--c-text-primary);
}

.tabs {
  display: flex;
  justify-content: space-between;
  color: var(--c-text-tertiary);
  border-bottom: 1px solid var(--c-gray-600);
}
.tabs a {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: none;
  border-top: 2px solid transparent;
  display: inline-flex;
  transition: 0.25s ease;
}
.tabs a.active,
.tabs a:hover,
.tabs a:focus {
  color: var(--c-text-primary);
  border-color: var(--c-text-primary);
}
.search-tabs {
  display: flex;
  color: var(--c-text-tertiary);
  border-bottom: 1px solid var(--c-gray-600);
}
.search-tabs a {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  padding-right: 3rem;
  text-decoration: none;
  border-top: 2px solid transparent;
  display: inline-flex;
  transition: 0.25s ease;
}
.search-tabs a.active,
.search-tabs a:hover,
.search-tabs a:focus {
  color: var(--c-text-primary);
  box-shadow: none;
}

.service-section {
  margin-left: 47px;
}
@media (max-width: 767.98px) {
  .service-section {
    margin-left: 20px;
  }
}
.search-label {
  margin-right: 25px;
  margin-top: 25px;
}
.invoice-label {
  font-size: 14px;
}
.search-tracking {
  margin-top: 32px;
}
.search-btn {
  margin-top: 25px;
}
option {
  color: #000;
}
input,
select {
  background: none;
  border: none;
  border-bottom: 2px solid #969593;
  color: #000000;
}
input:focus-visible,
select:focus-visible {
  border: none;
}
.order-input {
  margin-left: 23px;
}
.date-input {
  width: "200px";
}
.location-input {
  margin-left: 16px;
}
/* input[type=date]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
} */
.post-input {
  margin-left: 50px;
}
.status-input {
  margin-left: 33px;
}
.addrecord-form {
  border: 1px solid #969593;
  max-width: 450px;
  padding: 30px;
  margin-top: 32px;
  border-radius: 20px;
}
.table {
  color: #535353;
  margin-left: -75px;
  margin-top: 25px;
  white-space: nowrap;
}

.user-profile {
  display: flex;
  align-items: center;
  border: 0;
  background: transparent;
  cursor: pointer;
  color: var(--c-text-tertiary);
  transition: 0.25s ease;
}
.user-profile:hover,
.user-profile:focus {
  color: var(--c-text-primary);
}
.user-profile:hover span:last-child,
.user-profile:focus span:last-child {
  box-shadow: 0 0 0 4px var(--c-gray-800), 0 0 0 5px var(--c-text-tertiary);
}
.user-profile span:first-child {
  display: flex;
  font-size: 1.125rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--c-gray-600);
  font-weight: 300;
}
.user-profile span:last-child {
  transition: 0.25s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 1.5rem;
  flex-shrink: 0;
}

.icon-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 0;
  background-color: transparent;
  border: 1px solid var(--c-gray-500);
  color: var(--c-text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.25s ease;
  flex-shrink: 0;
}
.icon-button.large {
  width: 42px;
  height: 42px;
  font-size: 1.25em;
}
.icon-button i {
  transition: 0.25s ease;
}
.icon-button:hover,
.icon-button:focus {
  background-color: var(--c-gray-600);
  box-shadow: 0 0 0 4px var(--c-gray-800), 0 0 0 5px var(--c-text-tertiary);
}

.tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -moz-column-gap: 1rem;
  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 1.25rem;
}
@media (max-width: 700px) {
  .tiles {
    grid-template-columns: repeat(1, 1fr);
  }
}

.tile {
  padding: 1rem;
  border-radius: 8px;
  background-color: var(--c-olive-500);
  color: var(--c-gray-900);
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: 0.25s ease;
}
.tile:hover {
  transform: translateY(-5px);
}
.tile:focus-within {
  box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-olive-500);
}
.tile:nth-child(2) {
  background-color: var(--c-green-500);
}
.tile:nth-child(2):focus-within {
  box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-green-500);
}
.tile:nth-child(3) {
  background-color: var(--c-gray-300);
}
.tile:nth-child(3):focus-within {
  box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-gray-300);
}
.tile a {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}
.tile a .icon-button {
  color: inherit;
  border-color: inherit;
}
.tile a .icon-button:hover,
.tile a .icon-button:focus {
  background-color: transparent;
}
.tile a .icon-button:hover i,
.tile a .icon-button:focus i {
  transform: none;
}
.tile a:focus {
  box-shadow: none;
}
.tile a:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tile-header {
  display: flex;
  align-items: center;
}
.tile-header i {
  font-size: 2.5em;
}
.tile-header h3 {
  display: flex;
  flex-direction: column;
  line-height: 1.375;
  margin-left: 0.5rem;
}
.tile-header h3 span:first-child {
  font-weight: 600;
}
.tile-header h3 span:last-child {
  font-size: 0.825em;
  font-weight: 200;
}

.service-section > h2 {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}

.service-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-section-header > * + * {
  margin-left: 1.25rem;
}
@media (max-width: 1000px) {
  .service-section-header {
    display: none;
  }
}

.service-section-footer {
  color: var(--c-text-tertiary);
  margin-top: 1rem;
}

.search-field {
  display: flex;
  flex-grow: 1;
  position: relative;
}
.search-field input {
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 0;
  border-bottom: 1px solid var(--c-gray-600);
  background-color: transparent;
  padding-left: 1.5rem;
}
.search-field i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dropdown-field {
  display: flex;
  flex-grow: 1;
  position: relative;
}
.dropdown-field select {
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 0;
  border-bottom: 1px solid var(--c-gray-600);
  background-color: transparent;
  padding-right: 1.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: var(--c-text-tertiary);
  width: 100%;
}
.dropdown-field i {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.flat-button {
  border-radius: 6px;
  background-color: var(--c-gray-700);
  padding: 0.5em 1.5em;
  border: 0;
  color: var(--c-text-secondary);
  transition: 0.25s ease;
  cursor: pointer;
}
.flat-button:hover,
.flat-button:focus {
  background-color: var(--c-gray-600);
}

.mobile-only {
  display: none;
}
@media (max-width: 1000px) {
  .mobile-only {
    display: inline-flex;
  }
}

.transfer-section {
  margin-top: 2.5rem;
}

.transfer-section-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--c-gray-600);
}
.transfer-section-header h2 {
  font-size: 1.5rem;
}

.payments {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.payment {
  display: flex;
  align-items: center;
}
.payment + * {
  margin-top: 1rem;
}

.card {
  width: 125px;
  padding: 0.375rem;
  aspect-ratio: 3/2;
  flex-shrink: 0;
  border-radius: 6px;
  color: var(--c-gray-800);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 600;
}
.card.green {
  background-color: var(--c-green-500);
}
.card.olive {
  background-color: var(--c-olive-500);
}
.card.gray {
  background-color: var(--c-gray-300);
}
.card span:last-child {
  align-self: flex-end;
}

.payment-details {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: 1.5rem;
}
.payment-details h3 {
  font-size: 1rem;
  color: var(--c-text-tertiary);
}
.payment-details div {
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-top: 1px solid var(--c-gray-600);
  border-bottom: 1px solid var(--c-gray-600);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.payment-details div span {
  font-size: 1.5rem;
}

.filter-options {
  margin-left: 1.25rem;
  padding-left: 1.25rem;
  border-left: 1px solid var(--c-gray-600);
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}
.filter-options p {
  color: var(--c-text-tertiary);
  font-size: 0.875rem;
}
.filter-options p + * {
  margin-left: auto;
  margin-right: 0.75rem;
}
@media (max-width: 1000px) {
  .filter-options p {
    display: none;
  }
}

.transfers {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.transfer {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.875rem;
}
@media (max-width: 1000px) {
  .transfer {
    align-items: flex-start;
    flex-direction: column;
  }
}
.transfer + * {
  margin-top: 2rem;
}

.transfer-logo {
  background-color: var(--c-gray-200);
  border-radius: 4px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.transfer-logo img {
  width: 45%;
}

.transfer-details {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
@media (max-width: 1000px) {
  .transfer-details {
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: 1rem;
  }
}
.transfer-details div {
  width: calc(100% / 3 - 1rem);
}
@media (max-width: 1000px) {
  .transfer-details div {
    width: 100%;
  }
}
.transfer-details div + div {
  margin-left: 1rem;
}
@media (max-width: 1000px) {
  .transfer-details div + div {
    margin-left: 0;
    margin-top: 1rem;
  }
}
.transfer-details dd {
  color: var(--c-text-tertiary);
  margin-top: 2px;
}

.transfer-number {
  margin-left: 2rem;
  font-size: 1.125rem;
  flex-shrink: 0;
  width: 15%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1000px) {
  .transfer-number {
    margin-left: 0;
    margin-top: 1.25rem;
    width: 100%;
    justify-content: flex-start;
  }
}

.payment-section > h2 {
  font-size: 1.5rem;
}

.payment-section-header {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.payment-section-header p {
  color: var(--c-text-tertiary);
  font-size: 0.875rem;
}
.payment-section-header div {
  padding-left: 1rem;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.payment-section-header div > * + * {
  margin-left: 0.5rem;
}

.card-button {
  display: flex;
  width: 50px;
  height: 34px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: transparent;
  transition: 0.25s ease;
  border-radius: 4px;
  border: 2px solid var(--c-gray-600);
  color: var(--c-text-primary);
  cursor: pointer;
}
.card-button.mastercard svg {
  max-height: 15px;
}
.card-button:focus,
.card-button:hover,
.card-button.active {
  color: var(--c-gray-800);
  background-color: var(--c-white);
  border-color: var(--c-white);
}

.faq {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}
.faq p {
  color: var(--c-text-tertiary);
  font-size: 0.875rem;
}
.faq div {
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-top: 1px solid var(--c-gray-600);
  border-bottom: 1px solid var(--c-gray-600);
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}
.faq div label {
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px solid var(--c-gray-600);
}
.faq div input {
  border: 0;
  background-color: transparent;
  padding: 0.25em 0;
  width: 100%;
}

.payment-section-footer {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.save-button {
  border: 1px solid currentColor;
  color: var(--c-text-tertiary);
  border-radius: 6px;
  padding: 0.75em 2.5em;
  background-color: transparent;
  transition: 0.25s ease;
  cursor: pointer;
}
.save-button:focus,
.save-button:hover {
  color: var(--c-white);
}

.settings-button {
  display: flex;
  align-items: center;
  color: var(--c-text-tertiary);
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-left: 1.5rem;
  cursor: pointer;
  transition: 0.25s ease;
}
.settings-button i {
  margin-right: 0.5rem;
}
.settings-button:focus,
.settings-button:hover {
  color: var(--c-white);
}

select:focus,
a:focus,
textarea:focus,
button:focus {
  outline: 0;
  box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-gray-300);
}

.delete-confirmation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ) !important; /* Adjust the opacity as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Adjust the z-index value as needed */
}

.delete-confirmation-dialog {
  background-color: #fff !important;
  padding: 20px !important;
  border-radius: 15px;
  text-align: center;
  max-width: 400px;
}

.delete-confirmation-dialog h3 {
  margin-top: 0;
}

.delete-confirmation-dialog p {
  margin-bottom: 20px;
}

.delete-confirmation-dialog .buttons {
  display: flex;
  justify-content: center;
}

.delete-confirmation-dialog button {
  margin: 0 10px;
  padding: 8px 16px;
  border-radius: 4px;
  margin-top: 36px;
}

.delete-confirmation-dialog .addrecord-form {
  border: none;
  margin-left: 0;
  text-align: left;
}
.delete-confirmation-dialog form {
  color: #000;
}
.delete-confirmation-dialog form input,
.delete-confirmation-dialog form select {
  color: #000;
}
.delete-confirmation-dialog
  form
  input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(0%);
}
.delete-confirmation-dialog .addrecord-form {
  margin-top: 0px;
  padding-top: 10px;
}
.search-dates {
  min-width: 250px;
}
.search-dates input {
  margin-left: 0px;
}
.end-date {
  margin-left: 9px !important;
}
.download-csv-btn {
  margin-top: 30px;
}

.mobile-menu {
  position: relative;
}

.menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: #000000;
  font-size: 24px;
}

.menu-items {
  position: absolute;
  top: 56px;
  right: 0;
  z-index: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style: none;
}

.menu-items li {
  margin-bottom: 10px;
}

.menu-items li:last-child {
  margin-bottom: 0;
}

.menu-items a {
  color: #000 !important;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.menu-items a i {
  margin-right: 10px;
}

.menu-items a span {
  font-size: 14px;
}
.dashboard {
  width: 290px;
}
.btn-addanyways {
  margin-left: 10px;
}
.records-added {
  background-color: green;
  width: fit-content;
  padding: 14px;
  border-radius: 7px;
  margin-bottom: 15px;
  color: #fff;
}

.location-option {
  padding: 7px;
  width: 100px;
}
::-ms-reveal {
  filter: invert(100%);
}
.tracking-details .delete-confirmation-dialog {
  text-align: left;
  max-width: fit-content;
}
.track-events {
  margin-top: 10px;
}
.track {
  margin-left: 10px;
}
.track-box {
  margin-top: 20px;
}

/* .app {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
} */

.product-row {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-button,
.delete-button {
  background-color: #04ff00;
  color: rgb(0, 0, 0);
  border: none;
  padding: 0px 16px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
.delete-button {
  background-color: red;
  color: #fff;
}
#productprice,
#quantity,
#productName,
#author,
#productCode,
#productprice,
#producttotal,
#productdiscount,
.delete-button {
  margin-left: 5px;
}
#productCode {
  width: 100px;
}
#quantity {
  width: 54px;
}
#productprice,
#productdiscount {
  width: 100px;
}
#producttotal {
  width: 100px;
  background-color: #cbcbcb;
}
#producttotal-pc {
  background-color: #cbcbcb;
}
#producttotal:focus-visible,
#producttotal-pc:focus-visible {
  border: none;
}
.product-list {
  overflow: auto;
  padding-left: 73px;
  height: 40vh;
  overflow: auto;
}
.product-list-invoice {
  height: 34vh;
}
.scroll-container {
  margin-left: -75px;
  margin-top: 25px;
  white-space: nowrap;
}
.generate-invoice-btn {
  margin-right: 15px;
}
.invoiceDetail-input {
  border: none;
  border-bottom: 2px solid #969593;
}
.invoice-details {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.invoiceDetail-inputRow {
  flex: 1;
  display: flex;
  width: calc(50% - 5px);
}
@media (max-width: 900px) {
  .invoiceDetail-inputRow {
    width: 100%;
  }
  .invoice-details {
    gap: 10px;
  }
  .invoice-items {
    width: 450px;
  }
}

.accordion-del-img,
.accordion-edit-img,
.accordion-download-img {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  margin-right: 3px;
}
.accordion-del-img:hover,
.accordion-edit-img:hover,
.accordion-download-img:hover {
  cursor: pointer;
}
.accordion-head {
  display: flex;
}
.accordion-body .table-box {
  margin-left: -20px;
  height: fit-content;
}
.accordion-button {
  background-color: #acacacb5 !important;
}
.accordion-button:focus {
  color: #000;
  border-color: 1px solid #000 !important;
  box-shadow: #000 !important;
}

.accordion-invoice-details {
  display: flex;
  gap: 10px;
  align-items: baseline;
  font-size: 13px;
}

.accordion-invoice-number {
  width: 210px;
}
.invoice-pc-container {
  margin-left: 30px;
}
.scroll-container-pc {
  margin-top: 25px;
  height: 65vh;
  min-width: 330px;
}

.vertical {
  border-left: 2px solid black;
  height: 200px;
  position: absolute;
}
.invoice-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000000e8;
  padding: 20px;
  color: #fff;
  padding-left: 35px;
  padding-right: 35px;
  text-align: center;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
}
.invoice-footer span {
  font-size: 18px;
}
.invoice-details-container {
  max-height: 55vh;
  overflow: auto;
}

.invoice-details {
  flex: 1;
}

.generate-buttons {
  margin-left: 30px;
}
.generate-invoice-btn-pc {
  margin-top: 0;
}
.generate-invoice-btn-pc:hover {
  background-color: #ff9c11 !important;
  color: #000 !important;
}
#productName-pc {
  width: 300px;
}
#productAuthor-pc {
  width: 250px;
}
#productQuantity-pc {
  width: 85px;
}
#productPrice-pc {
  width: 110px;
}
#productDiscount-pc {
  width: 85px;
}
.invoice-pc-container table {
  border-spacing: 7px;
  border-collapse: separate;
}
.invoice-back-button {
  margin-top: -40px;
}
.invoice-footer-mobile {
  padding: 10px;
}
.edit-invoice-dialoge-pc {
  max-width: 800px !important;
  max-height: 700px !important;
}
.edit-scroll-conatiner {
  max-height: 40vh;
  width: 600px;
}
.edit-invoice-dialoge-pc .addrecord-form {
  margin-left: 30px;
}
.edit-invoice-dialoge-pc .delete-button {
  background-color: #ff0000;
  color: #fff;
  margin-top: 0 !important;
  border-radius: 30px !important;
}
.edit-invoice-dialoge-pc .date-input,
.edit-invoice-dialoge-pc .tracking-input {
  margin-left: 40px;
}
.edit-invoice-dialoge-pc .order-input {
  margin-left: 0px;
}
.edit-invoice-dialoge-pc .buttons {
  justify-content: flex-start;
}
.product-box {
  margin-left: -27px;
}
.react-autosuggest__suggestions-container {
  z-index: 1000;
  position: absolute;
  background-color: var(--c-gray-800);
  width: 300px;
  right: 10px;
  top: 10px;
  padding: 5px;
  max-width: 400px;
  border: 1px solid black;
  padding-top: 20px;
  display: none;
  overflow: auto;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  height: 600px;
}
/* .react-autosuggest__suggestions-container li{
  list-style: none;
} */
.orderReport {
  margin-top: 15px;
}
.radio-buttons {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 500px;
}
.radio-button {
  margin-right: 10px;
}
.radio-buttons input {
  margin-right: 10px;
}
/* .product-box{
  max-width: 57%;
} */
.app-body-main-content {
  max-width: 65vw;
}

.loader {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 6.25em;
  height: 6.25em;
  animation: rotate 2.4s linear infinite;
}
.white {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  animation: flash 2.4s linear infinite;
  opacity: 0;
}
.dot {
  position: absolute;
  margin: auto;
  width: 2.4em;
  height: 2.4em;
  border-radius: 100%;
  transition: all 1s ease;
}
.dot:nth-child(2) {
  top: 0;
  bottom: 0;
  left: 0;
  background: #ff4444;
  animation: dotsY 2.4s linear infinite;
}
.dot:nth-child(3) {
  left: 0;
  right: 0;
  top: 0;
  background: #ffbb33;
  animation: dotsX 2.4s linear infinite;
}
.dot:nth-child(4) {
  top: 0;
  bottom: 0;
  right: 0;
  background: #99cc00;
  animation: dotsY 2.4s linear infinite;
}
.dot:nth-child(5) {
  left: 0;
  right: 0;
  bottom: 0;
  background: #33b5e5;
  animation: dotsX 2.4s linear infinite;
}

.product-suggestion:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #f1f1f1;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  10% {
    width: 6.25em;
    height: 6.25em;
  }
  66% {
    width: 2.4em;
    height: 2.4em;
  }
  100% {
    transform: rotate(360deg);
    width: 6.25em;
    height: 6.25em;
  }
}

@keyframes dotsY {
  66% {
    opacity: 0.1;
    width: 2.4em;
  }
  77% {
    opacity: 1;
    width: 0;
  }
}
@keyframes dotsX {
  66% {
    opacity: 0.1;
    height: 2.4em;
  }
  77% {
    opacity: 1;
    height: 0;
  }
}

@keyframes flash {
  33% {
    opacity: 0;
    border-radius: 0%;
  }
  55% {
    opacity: 0.6;
    border-radius: 100%;
  }
  66% {
    opacity: 0;
  }
}
.edit-order-report-dialoge {
  width: 80vw;
  height: 90vh;
  max-height: 119vh;
  max-width: 119vw;
  padding: 0 !important;
}
.edit-order-report-dialoge .addrecord-form {
  max-width: none;
}
#order-report-edit-title {
  width: 40vw;
}
.edit-order-report-dialoge .invoice-details-container {
  max-height: 77vh;
}
#order-report-edit-dealer {
  width: 185px;
  border-bottom: none;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
}
.edit-order-save-btn {
  margin-top: 0px !important;
  margin-top: 5px !important;
}
.edit-order-save-btns {
  justify-content: flex-start !important;
}
.order-report-edit-button {
  margin-left: 10px;
}

.row-selected {
  background-color: #89ff89;
}

.row-not-selected {
  background-color: white;
}
